import React from 'react';

const HeartOutlined = ({ fill = '#737373' }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clipPath="url(#clip0_1000_623)">
    <path d="M11.6193 5.41417L12 5.8612L12.3807 5.41417C13.3789 4.24197 14.9114 3.5 16.5 3.5C19.3039 3.5 21.5 5.69614 21.5 8.5C21.5 10.2258 20.7284 11.8307 19.2692 13.6147C17.8043 15.4057 15.6992 17.3155 13.1141 19.6596L13.1134 19.6603L11.9987 20.675L10.8863 19.67L10.8852 19.669L10.8735 19.6583C8.29414 17.3144 6.19348 15.4055 4.73088 13.6161C3.27162 11.8308 2.5 10.2258 2.5 8.5C2.5 5.69614 4.69614 3.5 7.5 3.5C9.08861 3.5 10.6211 4.24197 11.6193 5.41417Z" stroke="#171717"/>
  </g>
  <defs>
    <clipPath id="clip0_1000_623">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
);

export default HeartOutlined;


