import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase.config';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(FirebaseApp);
const db = getFirestore(FirebaseApp);
const Providers = { google: new GoogleAuthProvider() };

export { FirebaseApp, auth, db, Providers };
