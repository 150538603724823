import React, { useState } from 'react';
import PostFormModalWrapper from './PostFormModalWrapper';
import PostForm from '../PostForm';
import { useUser } from '../../config/UserContext';
import { useSongs } from '../../config/SongContext';
import hasPostedToday from '../utils/hasPostedToday';
import styles from './PostFormModal.module.scss';

const PostFormModal = ({
  show,
  onClose,
  isEditing = false,
  postToEdit = null,
}) => {
  const { currentUser } = useUser();
  const { songs, deleteSong } = useSongs();
  const [isPosting, setIsPosting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const handlePostingChange = (posting) => {
    setIsPosting(posting);
  };

  const handleNextStep = () => {
    setCurrentStep(2);
  };

  const handlePreviousStep = () => {
    setCurrentStep(1);
  };

  const todaysSong = currentUser
    ? hasPostedToday(songs, currentUser.uid)
    : null;

  const hasPostedResult = currentUser
    ? hasPostedToday(songs, currentUser.uid)
    : null;

  const thumbnailURL = hasPostedResult ? hasPostedResult.thumbnailURL : null;

  return (
    <PostFormModalWrapper
      show={show}
      onClose={onClose}
      title={
        currentStep === 1 ? (
          'Post Song'
        ) : (
          <div className={styles.titleWithBack}>
            <button className={styles.backButton} onClick={handlePreviousStep}>
              ←
            </button>
            Song Info
          </div>
        )
      }
    >
      {todaysSong && !isEditing ? (
        <div className={styles.container}>
          <div className={styles.group}>
            <div className={styles.card}>
              <h3 className={styles.title}>Thanks for posting today!</h3>
              <div className={styles.songInfo}>
                <div className={styles.songInfoInner}>
                  <img src={thumbnailURL} alt="thumbnail" />
                  <span className={styles.songName}>{todaysSong.songName}</span>
                  <span className={styles.artist}>{todaysSong.songArtist}</span>
                </div>
                <button
                  className={styles.deleteButton}
                  onClick={() => deleteSong(todaysSong.id)}
                >
                  Delete Song
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={styles.loadingContainer}
          // style={{ height: '100dvh', width: '100dvw' }}
        >
          {isPosting ? (
            <div className={styles.postingContainer}>
              <p className={styles.postingText}>
                {isEditing ? 'Updating...' : 'Posting...'}
              </p>
            </div>
          ) : currentStep === 1 ? (
            <div>
              <PostForm
                onPostingChange={handlePostingChange}
                onSuccess={onClose}
                isEditing={isEditing}
                postToEdit={postToEdit}
              />
            </div>
          ) : (
            <div>
              <PostForm
                onPostingChange={handlePostingChange}
                onSuccess={onClose}
                isEditing={isEditing}
                postToEdit={postToEdit}
              />
            </div>
          )}
        </div>
      )}
    </PostFormModalWrapper>
  );
};

export default PostFormModal;
