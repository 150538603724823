import React from 'react';

import CommentList from '../CommentList';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import styles from './SongPage.module.scss';

const CommentSection = ({
  songDetails,
  currentUser,
  handleAddComment,
  handleDeleteComment,
  handleDeleteReply,
  handleAddReply,
  showConfirmDialog,
  confirmDelete,
  setShowConfirmDialog,
  commentFormRef,
  handleUserPageNavFromSong,
}) => (
  <div className={styles.body}>

    {showConfirmDialog && (
      <ConfirmDeleteDialog
        message="Are you sure you want to delete this song?"
        show={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        confirm={confirmDelete}
      />
    )}
    {Array.isArray(songDetails.comments) && (
      <CommentList
        comments={songDetails.comments}
        currentUser={currentUser}
        onDelete={handleDeleteComment}
        onDeleteReply={handleDeleteReply}
        onAddReply={handleAddReply}
        songId={songDetails.id}
        handleUserPageNavFromSong={handleUserPageNavFromSong}
      />
    )}
  </div>
);

export default CommentSection; 