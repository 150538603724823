import React from 'react';

const ReturnIcon = ({ width = 24, height = 24, stroke = "#0A0A0A", ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 8H14.5C16.433 8 18 9.567 18 11.5V11.5C18 13.433 16.433 15 14.5 15H6M6 15L8 12.5M6 15L8 17.5"
      stroke={stroke}
    />
  </svg>
);

export default ReturnIcon;
