import React, { useEffect } from 'react';
import styles from './Modal.module.scss';
import Close from '../../Icons/Close';

const Modal = ({ children, show, onClose, size = 'default', title }) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Cleanup function to ensure class is removed when component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className={styles.overlay}>
      <div
        className={`${styles.modal} ${
          size === 'small' ? styles.modalSmall : ''
        }`}
      >
        <div className={styles.header}>
          {title && <h3 className={styles.title}>{title}</h3>}
          <button onClick={onClose} className={styles.closeButton}>
            <Close fill='#171717' />
          </button>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;