import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import Modal from '../utils/Modal';
import styles from './EditProfile.module.scss';
import Edit from '../Icons/Edit';

const validateAndFormatUrl = (url) => {
  if (!url) return '';
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const EditProfile = ({
  show,
  onClose,
  userId,
  fetchUserDataAndSongs,
  currentUserDisplayName,
  currentAvatar,
}) => {
  const db = getFirestore();

  const [newDisplayName, setNewDisplayName] = useState(currentUserDisplayName);
  const [newAvatar, setNewAvatar] = useState('');
  const [website, setWebsite] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [bio, setBio] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setNewDisplayName(userData.displayName || currentUserDisplayName);
          setNewAvatar(userData.avatar || currentAvatar);
          setWebsite(userData.website || '');
          setInstagram(userData.instagram || '');
          setTwitter(userData.twitter || '');
          setBio(userData.bio || '');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (show) {
      fetchUserData();
    }
  }, [show, userId, db, currentUserDisplayName, currentAvatar]);

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      alert('File size exceeds 2MB. Please choose a smaller file.');
      return;
    }

    console.log('Selected File:', file);

    const storage = getStorage();
    const storageRef = ref(storage, 'avatars/' + userId);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Error uploading file:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            console.log('File available at', downloadURL);
            setNewAvatar(downloadURL);
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
          });
      }
    );
  };

  const handleEdit = async () => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const fieldsToUpdate = {
        displayName: newDisplayName,
        avatar: newAvatar,
        website: validateAndFormatUrl(website),
        instagram,
        twitter,
        bio,
      };
      console.log('Updating Firestore Document:', fieldsToUpdate);
      await updateDoc(userDocRef, fieldsToUpdate);
      await fetchUserDataAndSongs();
      onClose();
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  return (
    <Modal size="small" show={show} onClose={onClose} title="Edit your profile">
      <div className={styles.container}>
        <div className={styles.top}>
          <span className={styles.formLabel}>Profile pic</span>
          <label className={styles.avatarLabel}>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              className={styles.fileInput}
              value=""
            />
            <div
              className={styles.avatarPreview}
              style={{ backgroundImage: `url(${newAvatar || currentAvatar})` }}
            >
              <Edit fill="#fff" className={styles.editIcon} />
            </div>
          </label>

          <label className={styles.editLabel}>
            <span className={styles.formLabel}>Display Name</span>
            <input
              className={styles.input}
              type="text"
              placeholder="New display name"
              value={newDisplayName}
              onChange={(e) => setNewDisplayName(e.target.value)}
              maxLength={50}
            />
          </label>

          <label className={styles.editLabel}>
            <span className={styles.formLabel}>Website</span>
            <input
              className={styles.input}
              type="url"
              placeholder="Website URL"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              maxLength={100}
            />
          </label>

          <label className={styles.editLabel}>
            <span className={styles.formLabel}>Instagram</span>
            <input
              className={styles.input}
              type="text"
              placeholder="Instagram handle"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              maxLength={100}
            />
          </label>

          <label className={styles.editLabel}>
            <span className={styles.formLabel}>Twitter</span>
            <input
              className={styles.input}
              type="text"
              placeholder="Twitter handle"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              maxLength={100}
            />
          </label>

          <label className={styles.editLabel}>
            <span className={styles.formLabel}>Bio</span>
            <textarea
              className={styles.profileBio}
              placeholder="Tell us about yourself"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              maxLength={1000}
            />
          </label>
        </div>
        <button className={styles.cta} onClick={handleEdit}>
          Save changes
        </button>
      </div>
    </Modal>
  );
};

export default EditProfile;
