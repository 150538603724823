import React, { useState, useRef, useEffect, memo } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './config/UserContext';
import { SongsProvider } from './config/SongContext';
import { NavigationProvider } from './config/NavigationContext';
import { NotificationsProvider } from './config/NotificationContext';
import { AuthWrapper } from './config/AuthWrapper';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import SongPage from './components/SongPage/SongPage';
import Home from './components/Home/Home';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './config/firebase';

const getSongById = async (songId) => {
  try {
    if (typeof songId !== 'string' || songId.trim() === '') {
      throw new Error('Invalid song ID');
    }
    const songDoc = await getDoc(doc(db, 'songs', songId));
    if (songDoc.exists()) {
      return { id: songDoc.id, ...songDoc.data() };
    } else {
      console.error('No such song!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching song:', error);
    return null;
  }
};

// Optimized SongContainer with explicit lifecycle management
const SongContainer = memo(({ openSongPage, closeSongPage, currentSong }) => {
  const playerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 900);
  const mountedRef = useRef(false);

  useEffect(() => {
    // Handle initial mount
    if (!mountedRef.current) {
      console.log('[SongContainer] Initial mount');
      mountedRef.current = true;
    }

    const handleResize = () => {
      const newIsMobile = window.innerWidth < 900;
      if (newIsMobile !== isMobile) {
        setIsMobile(newIsMobile);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      if (playerRef.current?.destroy) {
        console.log('[SongContainer] Cleaning up player');
        playerRef.current.destroy();
      }
    };
  }, [isMobile]);

  // Handle song changes
  useEffect(() => {
    if (currentSong) {
      console.log('[SongContainer] Song changed:', currentSong.id);
    }
  }, [currentSong]);

  return (
    <div
      className="songPageContainer"
      style={{ display: currentSong || !isMobile ? 'block' : 'none' }}
    >
      {currentSong ? (
        <SongPage
          ref={playerRef}
          song={currentSong}
          onClose={closeSongPage}
          openSongPage={openSongPage}
        />
      ) : (
        !isMobile && <Home />
      )}
    </div>
  );
});

SongContainer.displayName = 'SongContainer';

// Main Root component with stable refs and handlers
const Root = () => {
  const [currentSong, setCurrentSong] = useState(null);
  const scrollPositionRef = useRef(0);
  const isTransitioningRef = useRef(false);
  const handlersRef = useRef({
    openSongPage: null,
    closeSongPage: null,
  });

  // Create stable handlers that persist across renders
  if (!handlersRef.current.closeSongPage) {
    handlersRef.current.closeSongPage = () => {
      console.log('[Root] Closing song page');
      setCurrentSong(null);
      window.history.pushState(null, '', '/');
      requestAnimationFrame(() => {
        window.scrollTo(0, scrollPositionRef.current);
      });
    };
  }

  if (!handlersRef.current.openSongPage) {
    handlersRef.current.openSongPage = async (songId) => {
      console.log('[Root] Request to open song:', songId);

      if (isTransitioningRef.current) {
        console.log('[Root] Transition already in progress');
        return;
      }

      if (songId === currentSong?.id) {
        console.log('[Root] Already on requested song');
        return;
      }

      try {
        isTransitioningRef.current = true;
        scrollPositionRef.current = window.scrollY;

        const nextSong = await getSongById(songId);
        if (!nextSong) {
          console.warn('[Root] Song not found, cannot navigate');
          alert('The song you are trying to view has been deleted.');
          return;
        }

        console.log('[Root] Setting current song:', nextSong.id);
        setCurrentSong(nextSong);
        window.history.pushState(null, '', `?songId=${songId}`);
      } catch (error) {
        console.error('[Root] Error during song transition:', error);
      } finally {
        isTransitioningRef.current = false;
      }
    };
  }

  // Only use StrictMode in development
  const appContent = (
    <Provider store={store}>
      <UserProvider>
        <SongsProvider>
          <AuthWrapper>
            <NotificationsProvider>
              <BrowserRouter>
                <NavigationProvider>
                  <div className="appWrapper">
                    <App
                      openSongPage={handlersRef.current.openSongPage}
                      closeSongPage={handlersRef.current.closeSongPage}
                      currentSong={currentSong}
                    />
                    <SongContainer
                      openSongPage={handlersRef.current.openSongPage}
                      closeSongPage={handlersRef.current.closeSongPage}
                      currentSong={currentSong}
                    />
                  </div>
                </NavigationProvider>
              </BrowserRouter>
            </NotificationsProvider>
          </AuthWrapper>
        </SongsProvider>
      </UserProvider>
    </Provider>
  );

  // Log song changes
  useEffect(() => {
    if (currentSong) {
      console.log('[Root] Current song updated:', currentSong.id);
    }
  }, [currentSong]);

  return process.env.NODE_ENV === 'development' ? (
    <React.StrictMode>{appContent}</React.StrictMode>
  ) : (
    appContent
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<Root />);

reportWebVitals();
