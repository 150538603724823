import React from 'react';
import Modal from '../utils/Modal';
import UserListItem from '../UserListItem/UserListItem';
import styles from './SongPage.module.scss';

const LikersModal = ({ showLikers, setShowLikers, likersDetails, handleUserPageNavFromSong }) => (
  <Modal
    show={showLikers}
    onClose={() => setShowLikers(false)}
    size="small"
    title="Likes"
  >
    <div className={styles.likersList}>
      {likersDetails.map((userDetail, index) => (
        <UserListItem
          key={index}
          user={userDetail}
          handleUserPageNavFromSong={handleUserPageNavFromSong}
          onCloseModal={() => setShowLikers(false)}
        />
      ))}
    </div>
  </Modal>
);

export default LikersModal; 