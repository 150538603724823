import React, { memo, useCallback } from 'react';
import Player from '../Player';
import styles from './SongPage.module.scss';

const PlayerSection = memo(({ songDetails, handleSongEnd, playerRef }) => {
  const handleVideoEnd = useCallback(() => {
    if (handleSongEnd) {
      handleSongEnd();
    }
  }, [handleSongEnd]);

  return (
    <div className={styles.header}>
      {songDetails.URL && (
        <Player
          key={songDetails.id}
          ref={playerRef}
          Url={songDetails.URL}
          onEnded={handleVideoEnd}
        />
      )}
    </div>
  );
});

PlayerSection.displayName = 'PlayerSection';

export default PlayerSection;
