import React, { useState, useEffect, useRef } from 'react';
import Overflow from '../../Icons/Overflow';
import styles from './OverflowMenu.module.scss';

function OverflowMenu({ options, buttonContent }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openUpwards, setOpenUpwards] = useState(false);

  const menuRef = useRef();

  const toggleMenu = () => {
    if (menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      const numberOfItems = options.length;
      const itemHeight = 40; // height of each item in pixels
      const padding = 16; // total padding in pixels
      const menuHeight = numberOfItems * itemHeight + padding;
      // Check if there is enough space below the button for the menu
      setOpenUpwards(rect.bottom + menuHeight > windowHeight);
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleClick = (option, e) => {
    e.preventDefault();
    option.handler();
    setIsOpen(false);
  };

  return (
    <div className={`${styles.overflowMenu}`} ref={menuRef}>
      <button className={styles.overflowButton} onClick={toggleMenu}>
        {buttonContent || <Overflow fill='#0a0a0a' />}
      </button>
      {isOpen && (
        <div className={`${openUpwards ? styles.menuUpwards : styles.menu}`}>
          {options.map((option, index) => (
            <button
              key={index}
              onClick={(e) => handleClick(option, e)}
              style={option.style}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default OverflowMenu;
