import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef, // Add useRef import
} from 'react';
import {
  getFirestore,
  query,
  collection,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  arrayUnion,
  arrayRemove,
  Timestamp,
  onSnapshot,
  updateDoc,
  where,
  deleteDoc,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const db = getFirestore();
const functions = getFunctions();
const SongsContext = createContext();

export const useSongs = () => {
  return useContext(SongsContext);
};

export const SongsProvider = ({ children }) => {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMoreSongs, setHasMoreSongs] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isAutoPlayEnabled, setIsAutoPlayEnabled] = useState(true);
  const songsRef = useRef([]); // Initialize the ref
  const pageSize = 50;

  // Keep songsRef in sync with songs state
  useEffect(() => {
    songsRef.current = songs;
  }, [songs]);

  useEffect(() => {
    const fetchInitialSongs = async () => {
      try {
        const songCollection = collection(db, 'songs');
        const initialQuery = query(
          songCollection,
          orderBy('timestamp', 'desc'),
          limit(pageSize)
        );

        const snapshot = await getDocs(initialQuery);
        const songList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSongs(songList);
        songsRef.current = songList; // Update ref immediately
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMoreSongs(songList.length === pageSize);
      } catch (error) {
        console.error('[SongsContext] Error fetching initial songs:', error);
      }
    };

    fetchInitialSongs();
  }, []);

  useEffect(() => {
    const songCollection = collection(db, 'songs');
    const unsubscribe = onSnapshot(songCollection, (snapshot) => {
      const songList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSongs(songList);
      songsRef.current = songList; // Update ref immediately
    });

    return () => unsubscribe();
  }, []);

  const loadMoreSongs = useCallback(async () => {
    if (!hasMoreSongs || !lastVisible || loadingMore) {
      console.log('[SongsContext] Skip loading more:', {
        hasMoreSongs,
        hasLastVisible: !!lastVisible,
        loadingMore,
      });
      return;
    }

    setLoadingMore(true);
    try {
      const songCollection = collection(db, 'songs');
      const nextQuery = query(
        songCollection,
        orderBy('timestamp', 'desc'),
        startAfter(lastVisible),
        limit(pageSize)
      );

      const snapshot = await getDocs(nextQuery);
      const newSongs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSongs((prevSongs) => {
        const updatedSongs = [...prevSongs, ...newSongs];
        songsRef.current = updatedSongs; // Update ref immediately
        return updatedSongs;
      });
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMoreSongs(newSongs.length === pageSize);
      console.log('[SongsContext] Loaded more songs:', newSongs.length);
    } catch (error) {
      console.error('[SongsContext] Error loading more songs:', error);
    } finally {
      setLoadingMore(false);
    }
  }, [hasMoreSongs, lastVisible, loadingMore]);

  const deleteSong = async (songId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      await deleteDoc(songRef);
      setSongs((prevSongs) => prevSongs.filter((song) => song.id !== songId));
      console.log(`Song with ID ${songId} deleted successfully.`);
    } catch (error) {
      console.error('Error deleting song:', error);
    }
  };

  const getNextSong = useCallback(
    async (currentSongId) => {
      console.log('[SongsContext] Getting random song for:', currentSongId);

      // Filter out the current song from the list
      const availableSongs = songsRef.current.filter(
        (song) => song.id !== currentSongId
      );

      if (availableSongs.length === 0) {
        console.log('[SongsContext] No other songs available');
        return null;
      }

      // Select a random song from the available songs
      const randomIndex = Math.floor(Math.random() * availableSongs.length);
      const randomSong = availableSongs[randomIndex];

      console.log('[SongsContext] Random song selected:', randomSong.id);
      return randomSong;
    },
    [songsRef]
  );

  const getSongById = async (songId) => {
    try {
      const songDoc = await getDoc(doc(db, 'songs', songId));
      if (songDoc.exists()) {
        return { id: songDoc.id, ...songDoc.data() };
      } else {
        console.error('No such song!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching song:', error);
      return null;
    }
  };

  const likeSong = async (songId, userId) => {
    console.log(`Liking song: ${songId} by user: ${userId}`);
    const songRef = doc(db, 'songs', songId);
    const userRef = doc(db, 'users', userId);

    try {
      const batch = writeBatch(db);

      batch.update(songRef, {
        likes: arrayUnion(userId),
      });

      const likedAt = Timestamp.now();
      batch.update(userRef, {
        likedSongs: arrayUnion({ songId, likedAt }),
      });

      await batch.commit();

      // Update global state
      setSongs((prevSongs) =>
        prevSongs.map((song) =>
          song.id === songId
            ? { ...song, likes: [...(song.likes || []), userId] }
            : song
        )
      );

      console.log(`Song liked successfully: ${songId}`);
      return true;
    } catch (error) {
      console.error('Error liking the song:', error);
      return false;
    }
  };

  const unlikeSong = async (songId, userId) => {
    const songRef = doc(db, 'songs', songId);
    const userRef = doc(db, 'users', userId);

    try {
      const batch = writeBatch(db);

      batch.update(songRef, {
        likes: arrayRemove(userId),
      });

      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();
      const likedSongObject = userData.likedSongs.find(
        (obj) => obj.songId === songId
      );

      if (likedSongObject) {
        batch.update(userRef, {
          likedSongs: arrayRemove(likedSongObject),
        });
      }

      await batch.commit();

      // Update local songs state
      setSongs((prevSongs) =>
        prevSongs.map((song) =>
          song.id === songId
            ? {
                ...song,
                likes: (song.likes || []).filter((id) => id !== userId),
              }
            : song
        )
      );
    } catch (error) {
      console.error('Error unliking the song:', error);
    }
  };

  const addComment = async (songId, newComment) => {
    console.log(
      `Adding comment to song: ${songId} by user: ${newComment.userId}`
    );
    const songRef = doc(db, 'songs', songId);

    if (
      !songId ||
      !newComment.userId ||
      !newComment.displayName ||
      !newComment.text
    ) {
      console.error('Error: One or more required fields are undefined.');
      return;
    }

    const commentToAdd = {
      id: Date.now().toString(), // Add a unique ID
      userId: newComment.userId,
      userName: newComment.displayName,
      commentText: newComment.text,
      timestamp: Timestamp.now(),
      likes: [],
      replies: [],
    };

    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData) {
        let updatedComments = songData.comments || [];
        updatedComments = [...updatedComments, commentToAdd];

        // Update Firestore
        await updateDoc(songRef, { comments: updatedComments });

        // Update local state immediately after adding a comment
        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );

        console.log('Comment added successfully:', commentToAdd);
      } else {
        console.error('Error: The song does not exist.');
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const addReply = async (songId, commentId, newReply) => {
    console.log(`Adding reply to song: ${songId}, comment: ${commentId}`);
    const songRef = doc(db, 'songs', songId);

    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              replies: [
                ...(comment.replies || []),
                {
                  id: Date.now().toString(),
                  userId: newReply.userId,
                  userName: newReply.displayName,
                  commentText: newReply.text,
                  timestamp: Timestamp.now(),
                  likes: [],
                },
              ],
            };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        console.log('Reply added successfully');
      } else {
        console.error('Song or comments not found');
      }
    } catch (error) {
      console.error('Error adding reply:', error);
      throw error;
    }
  };

  const fetchSongsByUser = async (userId) => {
    try {
      const songCollection = collection(db, 'songs');
      const userSongsQuery = query(
        songCollection,
        where('userId', '==', userId)
        // Temporarily remove the orderBy until index is built
        // orderBy('timestamp', 'desc')
      );

      const snapshot = await getDocs(userSongsQuery);
      const songs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log('Fetched user songs:', songs.length); // Debug log
      return songs;
    } catch (error) {
      console.error('Error fetching user songs:', error);
      return [];
    }
  };

  const deleteComment = async (songId, commentId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.filter(
          (comment) => comment.id !== commentId
        );

        await updateDoc(songRef, { comments: updatedComments });

        console.log(`Comment with ID ${commentId} deleted successfully.`);
      } else {
        console.error('Song or comments not found');
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const deleteReply = async (songId, commentId, replyIndex) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            const updatedReplies = comment.replies.filter(
              (_, index) => index !== replyIndex
            );
            return { ...comment, replies: updatedReplies };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        console.log(`Reply at index ${replyIndex} deleted successfully.`);
      } else {
        console.error('Song or comments not found');
      }
    } catch (error) {
      console.error('Error deleting reply:', error);
    }
  };

  //LIKE COMMENT LOGIC

  const likeComment = async (songId, commentId, userId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              likes: [...(comment.likes || []), userId],
            };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        // Update local state
        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );

        console.log(`Comment with ID ${commentId} liked successfully.`);
      }
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  const unlikeComment = async (songId, commentId, userId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              likes: (comment.likes || []).filter((id) => id !== userId),
            };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        // Update local state
        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );

        console.log(`Comment with ID ${commentId} unliked successfully.`);
      }
    } catch (error) {
      console.error('Error unliking comment:', error);
    }
  };

  //LIKE REPLY LOGIC
  const likeReply = async (songId, commentId, replyId, userId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData?.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            const updatedReplies = comment.replies.map((reply) => {
              if (reply.id === replyId) {
                return {
                  ...reply,
                  likes: [...(reply.likes || []), userId],
                };
              }
              return reply;
            });
            return { ...comment, replies: updatedReplies };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );
      }
    } catch (error) {
      console.error('Error liking reply:', error);
    }
  };

  const unlikeReply = async (songId, commentId, replyId, userId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData?.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            const updatedReplies = comment.replies.map((reply) => {
              if (reply.id === replyId) {
                return {
                  ...reply,
                  likes: (reply.likes || []).filter((id) => id !== userId),
                };
              }
              return reply;
            });
            return { ...comment, replies: updatedReplies };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );
      }
    } catch (error) {
      console.error('Error unliking reply:', error);
    }
  };

  const value = {
    songs,
    currentSong,
    setCurrentSong,
    setSongs,
    loadMoreSongs,
    deleteSong,
    getNextSong,
    getSongById,
    likeSong,
    unlikeSong,
    addComment,
    addReply,
    fetchSongsByUser,
    where,
    deleteComment,
    deleteReply,
    likeComment,
    unlikeComment,
    likeReply,
    unlikeReply,
  };

  return (
    <SongsContext.Provider value={value}>{children}</SongsContext.Provider>
  );
};

export default SongsProvider;
