import React from 'react';
import PostFormModal from '../PostFormModal';

const EditModal = ({ isEditModalOpen, setIsEditModalOpen, songDetails }) => (
  <PostFormModal
    show={isEditModalOpen}
    onClose={() => setIsEditModalOpen(false)}
    isEditing={true}
    postToEdit={songDetails}
  />
);

export default EditModal; 