import React, { useState } from 'react';
import styles from './UserPageSong.module.scss';
import LikeButton from '../LikeButton';
import CommentButton from '../CommentButton';
import { Link } from 'react-router-dom';
import OverflowMenu from '../utils/OverflowMenu';
import PostFormModal from '../PostFormModal';

const UserPageSong = ({
  song,
  currentUser,
  onLike,
  onUnlike,
  openSongPage,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const renderDescriptionWithLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const handleOpenSongPage = (e) => {
    e.preventDefault();
    openSongPage(song.id);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const menuOptions = [
    { label: 'Edit', handler: openEditModal },
  ];

  return (
    <div
      key={song.id}
      className={styles.songContainer}
      alt={`${song.songName} by ${song.songArtist}`}
      title={`${song.songName} by ${song.songArtist}`}
    >
      <div className={styles.songContainerHeader}>
        <span className={styles.songPostedDate}>{song.date}</span>
      </div>
      <div className={styles.songContainerBody}>
        <div className={styles.songLinkBody}>
          <Link
            className={styles.songLink}
            to={`/song/${song.id}`}
            onClick={handleOpenSongPage}
          >
            <div className={styles.songCluster}>
              <div
                className={styles.thumbnailImage}
                style={{ backgroundImage: `url(${song.thumbnailURL})` }}
              />
              <div className={styles.songInfo}>
                <span className={styles.songName}>{song.songName}</span>
                <span className={styles.songArtist}>by {song.songArtist}</span>
              </div>
            </div>
          </Link>
        </div>
        <div className={styles.songFooter}>
          <div className={styles.songCommentBox}>
            <span className={styles.songComment}>
              <span className={styles.descriptionBoxLabel}>
                About this song:
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: renderDescriptionWithLinks(song.description),
                }}
              />
            </span>
          </div>
          <div className={styles.utilButtons}>
            <LikeButton
              isLiked={song.likes?.includes(currentUser?.uid)}
              onLike={() => onLike(song.id)}
              onUnlike={() => onUnlike(song.id)}
              likeCount={song.likes ? song.likes.length : 0}
            />
            <CommentButton
              songId={song.id}
              commentCount={song.comments ? song.comments.length : 0}
              openSongPage={openSongPage}
            />
            {currentUser?.uid === song.userId && (
              <OverflowMenu options={menuOptions} />
            )}
          </div>
        </div>
      </div>

      {isEditModalOpen && (
        <PostFormModal
          show={isEditModalOpen}
          onClose={closeEditModal}
          isEditing={true}
          postToEdit={song}
        />
      )}
    </div>
  );
};

export default UserPageSong;
