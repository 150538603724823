// src/constants/LoadingMessages.js

const LOADING_MESSAGES = [
  'Finding songs',
  'Acquiring jams',
  'Collecting the tunes',
  'Amassing the playlist',
  'Fetching bops',
  'Loading the jams',
  'Grabbing the tune queue',
  'Setting the mood',
  'Curating the vibes',
  'Creating the atmosphere',

  'Finding your groove',

  'Choosing the perfect mood',

  'Reading the room',
  'Matching your energy',
  'Finding your frequency',
  'Tuning into your wavelength',
  'Catching your drift',
  'Getting in the zone',
  'Feeling the moment',
  'Brewing up something good',
  'Building the ambiance',
  'Crafting the perfect vibe',
  'Summoning good energy',
  'Assembling the atmosphere',
  'Mixing up the mood',

  'Cultivating good vibes',

  'Setting up the scene',

  'Getting the party started',

  'Syncing with your frequency',
  'Aligning the stars',
  'Making it happen',
  'Getting in the spirit',
  'Finding your flow',
  'Sparking joy',
  'Loading good vibes',
  'The spinner is spinning',
  'Setting intentions',

  'Spreading good energy',
  'Sharing the love',

  'Making it special',
  'My feet hurt',
  'I am a robot',
  'French Fries',
  'Post like your life depends on it',
  'Shared songs are good songs',
  'We love your favorite song',
  'I am a banana',
  'Finding your bliss',

  'Spinning up the welcome wheel',
  'Opening the gates to the vibe garden',

  'Who let the dogs out?',
  'Free Palestine',
  'Trans rights are human rights ✌️',

  'Digital welcome mat',
  'Reticulating splines',

  'Eating a sandwich',
  'Meaningless loading message',
  'Pithy loading message',
  'The sound of the anthroposcene',
  'Pizza',
  "I hope it's Friday where you are",
  'Sitting on a park bench',

  'Waiting is the hardest part',
  'Cheeseburger in paradise',
  'Wait right there, buddy',

  'One sec, I am just going to check my phone',

  'Checking my phone...',

  'Please stand around awkwardly while this loads',

  'Spinning, spinning, spinning',
  'Internet',
  'Sending beams into space...',

  'Welcome to the no hater zone',
  'All songs are good in their own way',
  'Do you have a favorite song today?',
  'Shoes off, please',
  'You smell good today',

  'You are looking so good today',
  "Shoe's untied",
  'Do not look directly at the spinner',
  'Post a song about cheese today',

  'Do you know what time it is?',
  'Do you smell that?',
  'What is that smell?',

  'You are special',

  'Be nice to each other',

  'Order a pizza tonight',

  'Make a wish...',

  'I am a computer',

  'It is lovely to see you',
  "We need Jungle, I'm afraid",

  'I once had a teacher who claimed that Debbie Harry invented hip hop. 🤨',
  'Music sounds better with you',

  'I am a monitor',

  'Now entering the jam lounge—no shoes, no problem',

  'Close the door behind you please',
  'Setting the stage',
  'Pushing open the portal',

  "I hope you're wearing clean underwear",
  'Did you brush your teeth today?',

  'Welcome to the melodic mansion—stay as long as you like',

  'Guiding you to the hidden jam chamber',

  'Drawing the circle — you are part of the www site now',
  'The website has a seat with your name on it - sit your butt down',

  'Swinging wide the doors to the jam cabin',
  "Knock knock — it's Good Song o'clock!",

  'Giddyup!',
];

export default LOADING_MESSAGES;
