import { useState, useEffect, useRef } from 'react';
import {
  getFirestore,
  doc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { auth } from '../../config/firebase';
import styles from './PostForm.module.scss';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Back from '../Icons/Back';
import { Link } from 'react-router-dom';
import ColorThief from 'color-thief-browser';

const extractSongDetails = (channelTitle, videoTitle) => {
  if (channelTitle.endsWith('- Topic')) {
    const artistName = channelTitle.slice(0, -7);
    return {
      songName: videoTitle,
      songArtist: artistName,
    };
  } else {
    const titlePattern = /^(.+) - (.+)$/;
    const match = videoTitle.match(titlePattern);
    if (match) {
      return {
        songName: match[2].trim(),
        songArtist: match[1].trim(),
      };
    }
  }
  return {
    songName: videoTitle,
    songArtist: '',
  };
};

const placeholderDescription = [
  'This is a great song!',
  'I love this song!',
  'This song is amazing!',
  'This song makes me cry!',
  'Legit bop, song of the summer!',
  'You need to hear this!',
  'Yall sleeping on this!',
  'CHOOOOOOOOOOOON!',
];

const fetchVideoDetails = async (videoId) => {
  try {
    const API_KEY = process.env.REACT_APP_YTAPI;
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${API_KEY}`
    );
    const data = await response.json();
    if (data.items && data.items.length > 0) {
      const { title, thumbnails, channelTitle } = data.items[0].snippet;
      return { snippet: { title, thumbnails, channelTitle } };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching video details:', error);
    return null;
  }
};

const PostForm = ({
  onSuccess,
  onPostingChange,
  isEditing = false,
  postToEdit = null,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [showFullForm, setShowFullForm] = useState(false);
  const [fetchedTitle, setFetchedTitle] = useState('');
  const [fetchedThumbnail, setFetchedThumbnail] = useState('');
  const [songName, setSongName] = useState('');
  const [songArtist, setSongArtist] = useState('');
  const [songURL, setSongURL] = useState('');
  const [description, setDescription] = useState('');
  const MAX_CHARS = 3000;
  const [remainingChars, setRemainingChars] = useState(MAX_CHARS);

  const [gradientColors, setGradientColors] = useState([]);

  const descriptionRef = useRef(null);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setRemainingChars(MAX_CHARS - e.target.value.length);
  };

  useEffect(() => {
    if (isEditing && postToEdit) {
      setSongName(postToEdit.songName);
      setSongArtist(postToEdit.songArtist);
      setSongURL(postToEdit.URL);
      setDescription(postToEdit.description);
      setFetchedTitle(postToEdit.songName);
      setFetchedThumbnail({ default: { url: postToEdit.thumbnailURL } });
      setShowFullForm(true);
      setIsUrlValid(true);
    }
  }, [isEditing, postToEdit]);

  useEffect(() => {
    if (fetchedThumbnail && fetchedThumbnail.default) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = fetchedThumbnail.default.url;
      img.onload = () => {
        const colorThief = new ColorThief();
        const colors = colorThief.getPalette(img, 3); // Get 3 dominant colors
        setGradientColors(colors.map((color) => `rgb(${color.join(',')})`));
      };
    }
  }, [fetchedThumbnail]);

  const gradientStyle =
    gradientColors.length > 0
      ? { background: `linear-gradient(135deg, ${gradientColors.join(', ')})` }
      : {};

  const clearUrlInput = () => {
    setSongURL('');
    setIsUrlValid(false);
    setShowFullForm(false);
  };

  const normalizeYouTubeURL = (url) => {
    const parsedURL = new URL(url);
    if (parsedURL.hostname === 'music.youtube.com') {
      parsedURL.hostname = 'www.youtube.com';
    }
    const videoId = parsedURL.searchParams.get('v');
    parsedURL.search = videoId ? `?v=${videoId}` : '';
    return parsedURL.toString();
  };

  const handleUrlChange = async (e) => {
    let songURL = e.target.value;
    if (songURL.includes('music.youtube.com')) {
      songURL = normalizeYouTubeURL(songURL);
    }

    let videoId = null;

    if (songURL.match(/^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+/)) {
      setIsUrlValid(true);
      setSongURL(songURL);

      if (songURL.includes('youtube.com')) {
        videoId = new URLSearchParams(new URL(songURL).search).get('v');
      } else if (songURL.includes('youtu.be')) {
        videoId = songURL.split('youtu.be/')[1].split('?')[0];
      }

      if (videoId) {
        try {
          const videoDetails = await fetchVideoDetails(videoId);
          if (videoDetails) {
            const { title, thumbnails, channelTitle } = videoDetails.snippet;
            const extractedDetails = extractSongDetails(channelTitle, title);
            setSongName(extractedDetails.songName);
            setSongArtist(extractedDetails.songArtist);
            setFetchedTitle(title);
            setFetchedThumbnail(thumbnails);
            setShowFullForm(true);
          }
        } catch (error) {
          console.error('Could not fetch video details:', error);
        }
      }
    } else {
      setIsUrlValid(false);
      setShowFullForm(false);
    }
  };

  const functions = getFunctions();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    onPostingChange(true);

    if (!auth.currentUser) {
      alert('Please log in to submit a song!');
      setIsLoading(false);
      onPostingChange(false);
      return;
    }

    const userId = auth.currentUser.uid;
    const timezoneOffset = new Date().getTimezoneOffset();
    const songData = {
      userId,
      songName,
      songArtist,
      URL: songURL,
      description,
      thumbnailURL:
        fetchedThumbnail.maxres?.url ||
        fetchedThumbnail.standard?.url ||
        fetchedThumbnail.high?.url ||
        fetchedThumbnail.medium?.url ||
        fetchedThumbnail.default?.url ||
        '',
      ...(isEditing ? {} : { timestamp: serverTimestamp() }),
      timezoneOffset,
    };

    try {
      if (isEditing && postToEdit) {
        const songRef = doc(getFirestore(), 'songs', postToEdit.id);
        await updateDoc(songRef, songData);
      } else {
        const postSong = httpsCallable(functions, 'postSong');
        const result = await postSong(songData);
        console.log('PostSong result:', result);
        if (!result.data.success) {
          throw new Error(result.data.message);
        }
      }
      console.log('Song posted successfully');
      onSuccess(); // Ensure this is called to close the modal
      setSongName('');
      setSongArtist('');
      setSongURL('');
      setDescription('');
    } catch (error) {
      alert('There was an error posting this song...');
      console.error(
        'Error with the following song data:',
        songData,
        'Error:',
        error
      );
    } finally {
      setIsLoading(false);
      onPostingChange(false);
    }
  };

  return (
    <div className={styles.formContainer} style={gradientStyle}>
      <form onSubmit={handleSubmit} className={styles.postform}>
        {isLoading ? (
          <div className={styles.postingContainer}>
            <p className={styles.postingText}>Posting...</p>
          </div>
        ) : null}
        {!showFullForm && (
          <div className={styles.ctaGroup}>
            <label className={styles.inputGroup}>
              <span className={styles.inputLabelCta}>Youtube URL</span>
              <input
                className={styles.urlInput}
                type="text"
                value={songURL}
                onChange={handleUrlChange}
                placeholder="Enter a YouTube URL"
                tabIndex={0}
                autoFocus
              />
              {!isUrlValid && (
                <span
                  style={{ marginLeft: '24px' }}
                  className={styles.inputLabel}
                >
                  ☝️ Please enter a valid YouTube url
                </span>
              )}
            </label>
          </div>
        )}
        {showFullForm && (
          <div className={styles.fetchedContainer}>
            <div className={styles.fetchedContainerBody}>
              <div className={styles.fetchedContainerLeft}>
                <div className={styles.fetchedContainerHeader}>
                  <button
                    type="button"
                    onClick={clearUrlInput}
                    className={styles.clearButton}
                  >
                    <Back fill="#171717" />
                    <span className={styles.fetchedContainerTitle}>
                      Song info
                    </span>
                  </button>
                </div>
                <div className={styles.songCluster}>
                  {fetchedThumbnail && fetchedThumbnail.default && (
                    <>
                      <div
                        className={styles.thumbnail}
                        style={{
                          backgroundImage: `url(${fetchedThumbnail.default.url})`,
                          backgroundBlendMode: 'multiply',
                        }}
                        alt="Video thumbnail"
                      />
                      <div className={styles.fetchedInfo}>
                        <span className={styles.fetchedName}>
                          {fetchedTitle}
                        </span>
                        <Link
                          target="_blank"
                          to={songURL}
                          className={styles.fetchedURL}
                        >
                          {songURL}
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.songInfoCluster}>
                  <label className={styles.inputLabel}>
                    Song Name:
                    <input
                      className={styles.songInput}
                      type="text"
                      value={songName}
                      onChange={(e) => setSongName(e.target.value)}
                    />
                  </label>
                  <label className={styles.inputLabel}>
                    Artist:
                    <input
                      className={styles.songInput}
                      type="text"
                      value={songArtist}
                      onChange={(e) => setSongArtist(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className={styles.fetchedContainerRight}>
                <div className={styles.fetchedContainerRightInner}>
                  <div className={styles.descriptionCluster}>
                    <p className={styles.descriptionLabel}>
                      What do you have to say about this song?:
                    </p>
                    <p className={styles.remainingChars}>{remainingChars}</p>
                  </div>
                  <div className={styles.textBox}>
                    <textarea
                      ref={descriptionRef}
                      className={styles.textBoxInner}
                      value={description}
                      maxLength={MAX_CHARS}
                      onChange={handleDescriptionChange}
                      placeholder={
                        placeholderDescription[
                          Math.floor(
                            Math.random() * placeholderDescription.length
                          )
                        ]
                      }
                    />
                  </div>

                  <button className={styles.submitCta} type="submit">
                    {isEditing ? 'Update' : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default PostForm;
