// routes.js
import SongListHome from '../components/SongListHome';
import Login from '../screens/Login';
import SongScreen from '../screens/SongScreen';
import UserScreen from '../screens/UserScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import About from '../screens/About';

const routes = [
  {
    path: '/',
    component: (props) => <SongListHome {...props} />,
    name: 'Home Page',
    protected: true,
    container: 'navWindow',
  },
  {
    path: '/login',
    component: Login,
    name: 'Login Screen',
    protected: false,
    container: 'navWindow',
  },
  {
    path: '/user/:userId',
    component: (props) => <UserScreen {...props} />,
    name: 'User Page',
    protected: false,
    container: 'navWindow',
  },
  {
    path: '/song/:id',
    component: SongScreen,
    name: 'Song Page',
    protected: false,
    container: 'mainBody',
  },
  {
    path: '/notifs/:userId',
    component: NotificationsScreen,
    name: 'Notifications',
    protected: true,
    container: 'mainBody',
  },
  {
    path: '/about',
    component: About,
    name: 'About',
    protected: false,
    container: 'mainBody',
  },
];

export default routes;
