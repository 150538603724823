import AboutPage from '../components/AboutPage';

const About = (props) => {
  return (
    <div>
      <AboutPage />
    </div>
  );
};

export default About;
