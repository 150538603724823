import React from 'react';
import NotificationsPage from '../components/NotificationsPage';
import { useNavigation } from '../config/NavigationContext';

const NotificationsScreen = ({ openSongPage }) => {
  const { handleUserPageNav } = useNavigation();

  return (
    <NotificationsPage
      openSongPage={openSongPage}
      handleUserPageNav={handleUserPageNav}
    />
  );
};

export default NotificationsScreen;
