import React, { useState } from 'react';

import AuthContainer from '../auth/AuthContainer';

import PostFormModal from '../PostFormModal';
import Check from '../Icons/Check';
import styles from './BottomBar.module.scss';
import { useUser } from '../../config/UserContext';
import { useNotifications } from '../../config/NotificationContext';
import { Link } from 'react-router-dom';
import { auth } from '../../config/firebase';

import Home from '../Icons/Home';
import Notification from '../Icons/Notification';
import NotificationActive from '../Icons/NotificationActive';
import Plus from '../Icons/Plus';
import useHasPostedToday from '../../hooks/useHasPostedToday';

const BottomBar = () => {
  const { currentUser } = useUser(); // Fetch currentUser from UserContext
  const [isModalOpen, setModalOpen] = useState(false);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const { hasUnreadNotifications } = useNotifications();

  const alreadyPosted = useHasPostedToday(userId);

  return (
    <>
      {!currentUser ? (
        <div className={styles.loggedOutContainer}>
          <AuthContainer />
        </div>
      ) : (
        <>
          <div className={styles.container}>
            <Link className={styles.navLink} to='/'>
              <Home fill='#171717' />
            </Link>
            <Link className={styles.navLink} to={`/notifs/${userId}`}>
              {hasUnreadNotifications ? (
                <NotificationActive fill='#171717' />
              ) : (
                <Notification fill='#171717' />
              )}
            </Link>

            <Link className={styles.navLink} to={`/user/${userId}`}>
              <img
                className={styles.bottomBarAvatar}
                src={currentUser?.avatar || '../avatar.png'}
                alt='avatar'
              />
            </Link>
            {alreadyPosted ? (
              <button
                className={styles.postedCTA}
                onClick={() => setModalOpen(true)}
              >
                <Check fill='#171717'/>
              </button>
            ) : (
              <button
                className={styles.postCTA}
                onClick={() => setModalOpen(true)}
              >
                <Plus fill='#171717' />
              </button>
            )}
          </div>
          <PostFormModal
            show={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default BottomBar;
