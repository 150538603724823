// NavBar.js
import React from 'react';
import styles from './SongPage.module.scss';
import Close from '../Icons/Close.js';
import Skip from '../Icons/Skip.js';

const NavBar = ({ songDetails, navbarState, onClose, onSkip }) => {
  return (
    <div className={styles.navBar}>
      <div className={styles.navBarTitle}>
        <div className={`${styles.navBarTitleContent} ${styles[navbarState]}`}>
          <div className={styles.defaultTitle}>A Good Song</div>
          <div className={styles.scrolledTitle}>
            <div className={styles.navBarSong}>{songDetails?.songName}</div>
            <div className={styles.navBarArtist}>{songDetails?.songArtist}</div>
          </div>
        </div>
      </div>
      <div className={styles.navBarButtons}>
        <div
          onClick={onSkip}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && onSkip()}
          className={styles.skipButton}
        >
          <Skip fill="#171717" />
        </div>
        <div
          onClick={onClose}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && onClose()}
          className={styles.closeButton}
        >
          <Close fill="#171717" />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
