// hooks/useHasPostedToday.js
import { useState, useEffect } from 'react';
import { useSongs } from '../config/SongContext';
import hasPostedToday from '../components/utils/hasPostedToday';

const useHasPostedToday = (userId) => {
  const { songs } = useSongs();
  const [alreadyPosted, setAlreadyPosted] = useState(false);

  useEffect(() => {
    if (songs && userId) {
      const userPostedToday = hasPostedToday(songs, userId); // assuming hasPostedToday is imported
      setAlreadyPosted(userPostedToday);
    }
  }, [songs, userId]);

  return alreadyPosted;
};

export default useHasPostedToday;
