import React from 'react';
import styles from './ConfirmationDialog.module.scss';

const ConfirmationDialog = ({ children, show, onClose, confirm }) => {
  if (!show) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <h3 className={styles.title}>Hold up...</h3>
        </div>
        <div className={styles.body}>
          {children}
        </div>
        <div className={styles.footer}>

        <button className={styles.confButton} onClick={onClose}>Cancel</button>
        <button className={styles.confButton} onClick={confirm}>Yes</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
