import { DateTime } from 'luxon';

const hasPostedToday = (songs, userId) => {
  // Get today's date based on user's local timezone
  const today = DateTime.local().startOf('day'); // Start of the day
  const endOfDay = DateTime.local().endOf('day'); // End of the day

  const foundSong = songs.find((song) => {
    const timestamp =
      song.timestamp &&
      'seconds' in song.timestamp &&
      'nanoseconds' in song.timestamp
        ? song.timestamp.toDate()
        : new Date();

    // Convert the timestamp to a DateTime object
    let songDateTime = DateTime.fromJSDate(timestamp);

    // Adjust the DateTime object based on the timezoneOffset saved with the song
    const adjustedTimestamp = songDateTime.plus({
      minutes: song.timezoneOffset,
    });

    // Check if the adjusted timestamp is between the start and end of today
    return (
      adjustedTimestamp >= today &&
      adjustedTimestamp <= endOfDay &&
      song.userId === userId
    );
  });

  return foundSong || null;
};

export default hasPostedToday;
