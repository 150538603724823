import React from 'react';
import SongListItem from '../SongListItem';
import styles from './SongListDay.module.scss';
import { useNavigation } from '../../config/NavigationContext';

const SongListDay = ({
  day,
  songs,
  onDelete,
  currentUser,
  setNavContent,
  openSongPage,
}) => {
  const SongCount = songs?.length || 0;
  const { handleUserPageNav } = useNavigation();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.dayTitle}>{day}</span>
        {SongCount > 0 ? (
          SongCount === 1 ? (
            <span className={styles.songCount}>{SongCount} song</span>
          ) : (
            <span className={styles.songCount}>{SongCount} songs</span>
          )
        ) : (
          <span className={styles.songCount}>No songs</span>
        )}
      </div>
      <li key={day}>
        {songs && songs.length > 0 ? (
          <ul className={styles.songList}>
            {songs
              .slice()
              .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
              .map((song, index) => (
                <SongListItem
                  key={index}
                  song={song}
                  onDelete={onDelete}
                  currentUser={currentUser}
                  setNavContent={setNavContent}
                  openSongPage={openSongPage}
                  handleUserPageNav={handleUserPageNav}
                />
              ))}
          </ul>
        ) : (
          <div className={styles.emptyContainer}>
            <p className={styles.emptyCopy}>No songs today 🫧</p>
          </div>
        )}
      </li>
    </div>
  );
};

export default SongListDay;
