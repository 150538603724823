import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../config/UserContext';
import { getFirestore, collection, addDoc, deleteDoc, query, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const db = getFirestore();
const functions = getFunctions();

export const useFollowUnfollow = () => {
  const { userId } = useParams();
  const { currentUser } = useUser();

  const handleFollow = useCallback(async () => {
    if (currentUser) {
      // Add currentUser.uid to userId's followers collection
      await addDoc(collection(db, 'users', userId, 'followers'), { userId: currentUser.uid });
      // Add userId to currentUser's following collection
      await addDoc(collection(db, 'users', currentUser.uid, 'following'), { userId });

      // Call the newFollowerNotification function
      const newFollowerNotification = httpsCallable(functions, 'newFollowerNotification');
      try {
        const result = await newFollowerNotification({ followedUserId: userId });
        console.log('New follower notification function result:', result.data);
      } catch (error) {
        console.error('Error calling new follower notification function:', error);
        if (error.details) {
          console.error('Error details:', error.details);
        }
      }
    }
  }, [currentUser, userId]);

  const handleUnfollow = useCallback(async () => {
    if (currentUser) {
      // Remove currentUser.uid from userId's followers collection
      const followerQuery = query(collection(db, 'users', userId, 'followers'), where('userId', '==', currentUser.uid));
      const followerSnapshot = await getDocs(followerQuery);
      followerSnapshot.forEach(doc => deleteDoc(doc.ref));
      
      // Remove userId from currentUser's following collection
      const followingQuery = query(collection(db, 'users', currentUser.uid, 'following'), where('userId', '==', userId));
      const followingSnapshot = await getDocs(followingQuery);
      followingSnapshot.forEach(doc => deleteDoc(doc.ref));
    }
  }, [currentUser, userId]);

  return { handleFollow, handleUnfollow };
};
