import React from 'react';

const Empty = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="144" height="33" viewBox="0 0 144 33" fill="none">
    <path d="M143.5 16.5C143.5 17.4813 143.068 18.4799 142.164 19.4923C141.257 20.5078 139.897 21.5113 138.104 22.4829C134.518 24.4259 129.296 26.1913 122.8 27.6799C109.817 30.6552 91.8562 32.5 72 32.5C52.1438 32.5 34.1831 30.6552 21.2 27.6799C14.7044 26.1913 9.48235 24.4259 5.89631 22.4829C4.10299 21.5113 2.7425 20.5078 1.83574 19.4923C0.931694 18.4799 0.5 17.4813 0.5 16.5C0.5 15.5187 0.931694 14.5201 1.83574 13.5077C2.7425 12.4922 4.10299 11.4887 5.89631 10.5171C9.48235 8.57412 14.7044 6.80867 21.2 5.3201C34.1831 2.34482 52.1438 0.5 72 0.5C91.8562 0.5 109.817 2.34482 122.8 5.3201C129.296 6.80867 134.518 8.57412 138.104 10.5171C139.897 11.4887 141.257 12.4922 142.164 13.5077C143.068 14.5201 143.5 15.5187 143.5 16.5Z" fill="black" stroke="#18181B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M139.903 22C142.556 20.2797 144 18.4285 144 16.5C144 7.3873 111.765 0 72 0C32.2355 0 0 7.3873 0 16.5C0 18.4285 1.4437 20.2797 4.09694 22C13.981 15.5914 40.6507 11 72 11C103.349 11 130.019 15.5914 139.903 22Z" fill="#E4E4E7"/>
    <path d="M143.5 16.5C143.5 17.4813 143.068 18.4799 142.164 19.4923C141.257 20.5078 139.897 21.5113 138.104 22.4829C134.518 24.4259 129.296 26.1913 122.8 27.6799C109.817 30.6552 91.8562 32.5 72 32.5C52.1438 32.5 34.1831 30.6552 21.2 27.6799C14.7044 26.1913 9.48235 24.4259 5.89631 22.4829C4.10299 21.5113 2.7425 20.5078 1.83574 19.4923C0.931694 18.4799 0.5 17.4813 0.5 16.5C0.5 15.5187 0.931694 14.5201 1.83574 13.5077C2.7425 12.4922 4.10299 11.4887 5.89631 10.5171C9.48235 8.57412 14.7044 6.80867 21.2 5.3201C34.1831 2.34482 52.1438 0.5 72 0.5C91.8562 0.5 109.817 2.34482 122.8 5.3201C129.296 6.80867 134.518 8.57412 138.104 10.5171C139.897 11.4887 141.257 12.4922 142.164 13.5077C143.068 14.5201 143.5 15.5187 143.5 16.5Z" stroke="#18181B"/>
  </svg>
);

export default Empty;
