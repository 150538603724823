import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import LikedSong from '../LikedSong';
import styles from './LikedSongsList.module.scss';
import DownIcon from '../Icons/Down';

const LikedSongsList = ({ userId, openSongPage }) => {
  const [likedSongs, setLikedSongs] = useState([]);
  const [isReverseOrder, setIsReverseOrder] = useState(false);
  const [isLikedSongsLoading, setIsLikedSongsLoading] = useState(true);

  const fetchLikedSongs = useCallback(async (userId) => {
    setIsLikedSongsLoading(true);
    try {
      const userRef = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      if (userData?.likedSongs) {
        const validLikedSongs = userData.likedSongs.filter(
          (songObj) => songObj && songObj.songId && songObj.likedAt
        );

        const likedSongsPromises = validLikedSongs.map(async (songObj) => {
          const songSnapshot = await getDoc(doc(db, 'songs', songObj.songId));
          if (songSnapshot.exists()) {
            return {
              id: songSnapshot.id,
              ...songSnapshot.data(),
              likedAt: songObj.likedAt,
              uniqueId: `${songSnapshot.id}-${songObj.likedAt.toDate?.().getTime() || Date.now()}`
            };
          }
          return null;
        });

        const fetchedSongs = (await Promise.all(likedSongsPromises)).filter(Boolean);
        setLikedSongs(fetchedSongs);
      } else {
        setLikedSongs([]);
      }
    } catch (error) {
      console.error('Error fetching liked songs:', error);
      setLikedSongs([]);
    } finally {
      setIsLikedSongsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchLikedSongs(userId);
    }
  }, [userId, fetchLikedSongs]);

  const toggleOrder = () => {
    setIsReverseOrder((prev) => !prev);
  };

  const sortedSongs = [...likedSongs].sort((a, b) => {
    const timeA = a.likedAt?.toDate?.() || new Date(0);
    const timeB = b.likedAt?.toDate?.() || new Date(0);
    return isReverseOrder ? timeA - timeB : timeB - timeA;
  });

  if (isLikedSongsLoading) {
    return (
      <div className={styles.loading}>
        <p className={styles.loadingText}>
        Loading liked songs...
        </p>
      </div>
    );
  }

  return (
    <div className={styles.likesArray}>
      <div className={styles.headContainer}>
        <p className={styles.headText}>Liked Songs</p>
      <button 
        onClick={toggleOrder}
        className={`${styles.sortButton} ${isReverseOrder ? styles.rotated : ''}`}
      >
         <DownIcon />
        </button>
      </div>
      {sortedSongs.length > 0 ? (
        <div className={styles.songsGrid}>
          {sortedSongs.map((song) => (
            <LikedSong
              key={song.uniqueId}
              song={song}
              openSongPage={openSongPage}
            />
          ))}
        </div>
      ) : (
        <p className={styles.noSongs}>No liked songs found.</p>
      )}
    </div>
  );
};

export default LikedSongsList;
