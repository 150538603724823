import React from 'react';
import moment from 'moment';
import styles from './SongPage.module.scss';

const SongInfo = ({ songDetails, userDetails, handleUserPageNavFromSong, songInfoRef }) => (
  <div ref={songInfoRef} className={styles.pageHeader}>
    <div className={styles.songInfo}>
      <h3 className={styles.title}>{songDetails.songName}</h3>
      <span className={styles.artist}>by {songDetails.songArtist}</span>
    </div>
    <div className={styles.postDetails}>
      <div
        onClick={() => handleUserPageNavFromSong(songDetails.userId)}
        className={styles.userLink}
      >
        <img
          src={userDetails?.avatar}
          alt={userDetails?.displayName}
          className={styles.avatar}
        />
        <div className={styles.postedByStack}>
          <span className={styles.postedBy}>{userDetails?.displayName}</span>
          <span className={styles.postedAt}>
            {moment(songDetails.timestamp.toDate()).fromNow()}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default SongInfo; 