import React, { useRef, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Comments from '../Icons/Comments';
import styles from './CommentButton.module.scss';

function CommentButton({ songId, commentCount, openSongPage }) {
  const navigate = useNavigate();
  const location = useLocation();
  const buttonRef = useRef(null);

  const isOnSongPage = location.pathname.startsWith('/song/');

  const scrollToComments = () => {
    const commentForm = document.getElementById('comment-form');
    if (commentForm) {
      commentForm.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClick = useCallback((e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (isOnSongPage) {
      scrollToComments();
    } else if (openSongPage) {
      openSongPage(songId);
    } else {
      navigate(`/song/${songId}`);
    }
  }, [isOnSongPage, openSongPage, songId, navigate]);

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      button.addEventListener('click', handleClick);
      return () => button.removeEventListener('click', handleClick);
    }
  }, [handleClick]);

  return (
    <button ref={buttonRef} className={styles.commentButton}>
      {commentCount ? commentCount : 0}
      <Comments fill='#0a0a0a' />
    </button>
  );
}

export default CommentButton;
