import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSongs } from '../config/SongContext';
import { useUser } from '../config/UserContext';
import SongPage from '../components/SongPage/SongPage';

const SongScreen = ({ onClose, handleUserPageNav }) => {
  const { id } = useParams();
  const { getSongById, currentSong, setCurrentSong, likeSong, unlikeSong, addComment } = useSongs();
  const { currentUser } = useUser();

  useEffect(() => {
    const fetchSong = async () => {
      const fetchedSong = await getSongById(id);
      setCurrentSong(fetchedSong);
    };
    fetchSong();
  }, [id, getSongById, setCurrentSong]);

  const handleLike = useCallback(async () => {
    if (currentSong && currentSong.id && currentUser) {
      await likeSong(currentSong.id, currentUser.uid);
      const updatedSong = await getSongById(currentSong.id);
      setCurrentSong(updatedSong);
    }
  }, [currentSong, currentUser, likeSong, getSongById, setCurrentSong]);

  const handleUnlike = useCallback(async () => {
    if (currentSong && currentSong.id && currentUser) {
      await unlikeSong(currentSong.id, currentUser.uid);
      const updatedSong = await getSongById(currentSong.id);
      setCurrentSong(updatedSong);
    }
  }, [currentSong, currentUser, unlikeSong, getSongById, setCurrentSong]);

  const handleAddComment = useCallback(async (commentText) => {
    if (currentSong && currentSong.id && currentUser) {
      const newComment = {
        userId: currentUser.uid,
        displayName: currentUser.displayName,
        text: commentText.trim(),
      };
      await addComment(currentSong.id, newComment);
      const updatedSong = await getSongById(currentSong.id);
      setCurrentSong(updatedSong);
    }
  }, [currentSong, currentUser, addComment, getSongById, setCurrentSong]);

  if (!currentSong) {
    return <div>Loading...</div>;
  }

  return (
    <SongPage
      song={currentSong}
      currentUser={currentUser}
      onClose={onClose}
      handleUserPageNav={handleUserPageNav}
      handleLike={handleLike}
      handleUnlike={handleUnlike}
      handleAddComment={handleAddComment}
    />
  );
};

export default SongScreen;
