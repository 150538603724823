import React from 'react';
import ConfirmationDialog from '../utils/ConfirmationDialog';

const ConfirmDeleteDialog = ({ show, onClose, confirm, message }) => {
    return (
        <ConfirmationDialog show={show} onClose={onClose} confirm={confirm}>
            {message || "Are you sure you want to delete this item?"}
        </ConfirmationDialog>
    );
};

export default ConfirmDeleteDialog;
