import React, { useState } from 'react';
import styles from './BrandHead.module.scss';
import BrandHeadLogo from '../Icons/BrandHeadLogo';
import { useSongs } from '../../config/SongContext';
import External from '../Icons/External';
import Record from '../Icons/Record';
import Modal from '../utils/Modal';
import AboutPage from '../AboutPage/AboutPage';

function BrandHead() {
  const { songs } = useSongs();
  const totalSongs = songs.length;
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const toggleAboutModal = () => {
    setIsAboutModalOpen((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.recordIcon} onClick={toggleAboutModal}>
            <Record />
          </div>
          <div className={styles.stacked}>
            <p className={styles.paragraph}>Songs shared</p>
            <span className={styles.strong}>{totalSongs}</span>
          </div>
        </div>
        <a
            href="https://digthis.club"
            target="_blank"
            className={styles.stackedRight}
            rel="noreferrer"
          >
        
          <p className={styles.paragraph}>Looking for inspiration?</p>

            <span className={styles.strong}>Dig This
              <External id="external-link" />
              </span> 
        
          </a>
      </div>
      <div className={styles.body}>
        <p>Welcome to</p>
        <BrandHeadLogo />
      </div>
      <Modal
        size="small"
        show={isAboutModalOpen}
        onClose={toggleAboutModal}
        title="Good Song Club"
      >
        <AboutPage />
      </Modal>
    </div>
  );
}

export default BrandHead;
