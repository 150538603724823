import React from 'react';
import styles from './Home.module.scss';

const Home = () => {
  return (
    <div className={`${styles.container} hide-on-mobile`}>
      <div className={styles.topBar}></div>
    </div>
  );
};

export default Home;
