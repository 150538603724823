import React from 'react';
import HeartFilled from '../Icons/HeartFilled';
import HeartOutlined from '../Icons/HeartOutlined';
import styles from './LikeButton.module.scss';

function LikeButton({ isLiked, onLike, onUnlike, likeCount }) {
  const handleClick = (e) => {
    e.preventDefault();
    isLiked ? onUnlike() : onLike();
  };
  return (
    <button
      className={styles.likeButton}
      onClick={handleClick}
      style={isLiked ? { color: '#0a0a0a' } : { color: '#0a0a0a' }}
    >
      {likeCount || 0}
      {isLiked ? (
        <HeartFilled fill='#fcd34d' />
      ) : (
        <HeartOutlined fill='#0a0a0a' />
      )}
    </button>
  );
}

export default LikeButton;
