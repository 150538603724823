import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  updateDoc,
  writeBatch,
  onSnapshot,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const NotificationsContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [lastNotification, setLastNotification] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();
  const auth = getAuth();

  const fetchNotifications = useCallback(
    async (user, lastDoc = null) => {
      try {
        let notificationsQuery = query(
          collection(db, 'notifications'),
          where('recipientId', '==', user.uid),
          orderBy('timestamp', 'desc'),
          limit(30)
        );

        if (lastDoc) {
          notificationsQuery = query(notificationsQuery, startAfter(lastDoc));
        }

        const querySnapshot = await getDocs(notificationsQuery);
        const notificationsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        return {
          notifications: notificationsList,
          lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
          hasMore: querySnapshot.docs.length === 30,
        };
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setError(error.message);
        return { notifications: [], lastDoc: null, hasMore: false };
      }
    },
    [db]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        setLoading(true);
        const { notifications, lastDoc, hasMore } =
          await fetchNotifications(user);
        setNotifications(notifications);
        setLastNotification(lastDoc);
        setHasMore(hasMore);
        setLoading(false);
      } else {
        setNotifications([]);
        setLastNotification(null);
        setHasMore(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, fetchNotifications]);

  useEffect(() => {
    if (!currentUser) return;

    const notificationsQuery = query(
      collection(db, 'notifications'),
      where('recipientId', '==', currentUser.uid),
      orderBy('timestamp', 'desc'),
      limit(30)
    );

    const unsubscribe = onSnapshot(notificationsQuery, async (snapshot) => {
      const newNotifications = [];
      for (const docSnapshot of snapshot.docs) {
        const notification = { id: docSnapshot.id, ...docSnapshot.data() };
        if (notification.relatedSongId) {
          const songDoc = await getDoc(
            doc(db, 'songs', notification.relatedSongId)
          );
          if (songDoc.exists()) {
            newNotifications.push(notification);
          } else {
            console.warn('Notification for deleted song:', notification.id);
            // Optionally, delete the notification from Firestore
            // await deleteDoc(doc(db, 'notifications', notification.id));
          }
        } else {
          newNotifications.push(notification);
        }
      }
      setNotifications(newNotifications);
    });

    return () => unsubscribe();
  }, [currentUser, db]);

  const fetchMoreNotifications = useCallback(async () => {
    if (!hasMore || !lastNotification || !currentUser) return;

    const {
      notifications: newNotifications,
      lastDoc,
      hasMore: moreAvailable,
    } = await fetchNotifications(currentUser, lastNotification);

    setNotifications((prevNotifications) => [
      ...prevNotifications,
      ...newNotifications,
    ]);
    setLastNotification(lastDoc);
    setHasMore(moreAvailable);
  }, [hasMore, lastNotification, currentUser, fetchNotifications]);

  const markAllAsRead = useCallback(async () => {
    if (!currentUser) return;
    const batch = writeBatch(db);
    notifications.forEach((notification) => {
      if (!notification.isRead) {
        const notificationRef = doc(db, 'notifications', notification.id);
        batch.update(notificationRef, { isRead: true });
      }
    });

    try {
      await batch.commit();
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          isRead: true,
        }))
      );
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      setError(error.message);
    }
  }, [currentUser, db, notifications]);

  const markNotificationAsRead = useCallback(
    async (notificationId) => {
      try {
        const notificationRef = doc(db, 'notifications', notificationId);
        await updateDoc(notificationRef, { isRead: true });

        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === notificationId
              ? { ...notification, isRead: true }
              : notification
          )
        );
      } catch (error) {
        console.error('Error marking notification as read:', error);
        setError(error.message);
      }
    },
    [db]
  );

  const hasUnreadNotifications = useMemo(() => {
    return notifications.some((notification) => !notification.isRead);
  }, [notifications]);

  const value = useMemo(
    () => ({
      notifications,
      hasUnreadNotifications,
      markAllAsRead,
      markNotificationAsRead,
      fetchMoreNotifications,
      loading,
      error,
    }),
    [
      notifications,
      hasUnreadNotifications,
      markAllAsRead,
      markNotificationAsRead,
      fetchMoreNotifications,
      loading,
      error,
    ]
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
