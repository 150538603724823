import React, { useState, useEffect } from 'react';
import styles from './CookieNotification.module.scss';

const CookieNotification = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (!cookieAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={styles.cookieNotification}>
      <div className={styles.cookieNotificationContent}>

      <h3 className={styles.cookieHeader}>Guess what:</h3>
      <p>This site uses cookies.</p>
      </div>
      <button onClick={handleAccept} className={styles.acceptButton}>Ok, fine.</button>
    </div>
  );
};

export default CookieNotification; 