import React from 'react';
import styles from './PostFormModalWrapper.module.scss';
import Close from '../Icons/Close.js';

const PostFormModalWrapper = ({ show, onClose, title, children }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>

          <button className={styles.closeButton} onClick={onClose}>
            <Close fill={'#000'} />
          </button>
        
        <div className={styles.modalBody}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PostFormModalWrapper; 