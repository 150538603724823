import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from './CommentList.module.scss';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import { Link } from 'react-router-dom';
import OverflowMenu from '../utils/OverflowMenu';
import { useUser } from '../../config/UserContext';
import { useSongs } from '../../config/SongContext';
import LikeButton from '../LikeButton';
import DOMPurify from 'dompurify';
import CommentForm from '../CommentForm/CommentForm';

const CommentList = ({
  comments,
  currentUser,
  onDelete,
  onDeleteReply,
  onAddReply,
  songId,
  handleUserPageNavFromSong,
}) => {
  const { likeComment, unlikeComment, likeReply, unlikeReply } = useSongs();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [selectedReplyIndex, setSelectedReplyIndex] = useState(null);
  const [userAvatars, setUserAvatars] = useState({});
  const [replyInputVisible, setReplyInputVisible] = useState({});

  const { getUserDetailsById } = useUser();
  const handleLike = (songId, comment, userId, reply = null) => {
    if (reply) {
      likeReply(songId, comment.id, reply.id, userId);
    } else {
      likeComment(songId, comment.id, userId);
    }
  };

  const handleUnlike = (songId, comment, userId, reply = null) => {
    if (reply) {
      unlikeReply(songId, comment.id, reply.id, userId);
    } else {
      unlikeComment(songId, comment.id, userId);
    }
  };

  useEffect(() => {
    const fetchAvatars = async () => {
      const uniqueUserIds = [
        ...new Set(
          comments.flatMap((comment) => [
            comment.userId,
            ...(comment.replies || []).map((reply) => reply.userId),
          ])
        ),
      ];

      const avatars = {};

      for (const userId of uniqueUserIds) {
        try {
          const userDetails = await getUserDetailsById(userId);
          avatars[userId] = {
            avatar: userDetails?.avatar || defaultAvatar,
            displayName:
              userDetails?.displayName ||
              userDetails?.userName ||
              'Unknown User',
          };
        } catch (error) {
          console.error('Error fetching user details:', error);
          avatars[userId] = {
            avatar: defaultAvatar,
            displayName: 'Unknown User',
          };
        }
      }

      setUserAvatars(avatars);
    };

    if (comments && comments.length > 0) {
      fetchAvatars();
    }
  }, [comments, getUserDetailsById]);

  const handleOpenConfirmDialog = (commentId, replyIndex = null) => {
    setSelectedCommentId(commentId);
    setSelectedReplyIndex(replyIndex);
    setShowConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCommentId !== null) {
      if (selectedReplyIndex !== null) {
        // Handle reply deletion
        if (typeof onDeleteReply === 'function') {
          onDeleteReply(selectedCommentId, selectedReplyIndex);
        } else {
          // Fall back to onDelete if onDeleteReply is not provided
          console.warn(
            'onDeleteReply is not defined, falling back to onDelete'
          );
          onDelete(selectedCommentId, selectedReplyIndex);
        }
      } else {
        // Handle comment deletion
        onDelete(selectedCommentId);
      }
    }
    setShowConfirmDialog(false);
    setSelectedCommentId(null);
    setSelectedReplyIndex(null);
  };

  const defaultAvatar = '../avatar.png';

  const renderText = (text) => {
    const sanitizedHTML = DOMPurify.sanitize(text);
    return (
      <p
        className={styles.commentText}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      />
    );
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp.toDate === 'function') {
      return moment(timestamp.toDate()).fromNow();
    } else if (timestamp instanceof Date) {
      return moment(timestamp).fromNow();
    } else if (timestamp && timestamp.seconds) {
      return moment.unix(timestamp.seconds).fromNow();
    } else {
      return 'Unknown time';
    }
  };

  const toggleReplyInput = (commentId) => {
    setReplyInputVisible((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  return (
    <div className={styles.comments}>
      Comments:
      <div className={styles.commentList}>
        {comments?.map((comment, commentIndex) => {
          const commentMenuOptions = [
            {
              label: 'Delete',
              handler: () => handleOpenConfirmDialog(comment.id),
              style: { color: '#EF4444' },
            },
          ];
          return (
            <div className={styles.container} key={comment.id}>
              <div className={styles.mainComment}>
                <div className={styles.userCluster}>
                  <Link
                    to={`/user/${comment.userId}`}
                    className={styles.userLink}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUserPageNavFromSong(comment.userId);
                    }}
                  >
                    <img
                      src={userAvatars[comment.userId]?.avatar || defaultAvatar}
                      alt={
                        userAvatars[comment.userId]?.displayName ||
                        'Unknown User'
                      }
                      className={styles.avatar}
                    />
                    <div className={styles.infoStacked}>
                      <span className={styles.userName}>
                        {userAvatars[comment.userId]?.displayName ||
                          'Unknown User'}
                      </span>
                      <span className={styles.postTime}>
                        {formatTimestamp(comment.timestamp)}
                      </span>
                    </div>
                  </Link>

                  {currentUser && comment.userId === currentUser?.uid && (
                    <OverflowMenu options={commentMenuOptions} />
                  )}
                </div>
                <div className={styles.commentBody}>
                  {renderText(
                    comment.commentText || comment.text || 'No comment text'
                  )}
                  <LikeButton
                    isLiked={comment.likes?.includes(currentUser?.uid)}
                    onLike={() => handleLike(songId, comment, currentUser?.uid)}
                    onUnlike={() =>
                      handleUnlike(songId, comment, currentUser?.uid)
                    }
                    likeCount={comment.likes?.length || 0}
                  />
                </div>
              </div>

              {comment.replies && comment.replies.length > 0 && (
                <div className={styles.replies}>
                  {comment.replies.map((reply, replyIndex) => {
                    const replyMenuOptions = [
                      {
                        label: 'Delete',
                        handler: () =>
                          handleOpenConfirmDialog(comment.id, replyIndex),
                        style: { color: '#EF4444' },
                      },
                    ];
                    return (
                      <div className={styles.reply} key={reply.id}>
                        <div className={styles.userCluster}>
                          <Link
                            to={`/user/${reply.userId}`}
                            className={styles.userLink}
                            onClick={(e) => {
                              e.preventDefault();
                              handleUserPageNavFromSong(reply.userId);
                            }}
                          >
                            <img
                              src={
                                userAvatars[reply.userId]?.avatar ||
                                defaultAvatar
                              }
                              alt={
                                userAvatars[reply.userId]?.displayName ||
                                'Unknown User'
                              }
                              className={styles.avatar}
                            />
                            <div className={styles.infoStacked}>
                              <span className={styles.userName}>
                                {userAvatars[reply.userId]?.displayName ||
                                  'Unknown User'}
                              </span>
                              <span className={styles.postTime}>
                                {formatTimestamp(reply.timestamp)}
                              </span>
                            </div>
                          </Link>
                          {currentUser && reply.userId === currentUser?.uid && (
                            <OverflowMenu options={replyMenuOptions} />
                          )}
                        </div>
                        <div className={styles.replyBody}>
                          {renderText(
                            reply.commentText || reply.text || 'No reply text'
                          )}
                          <LikeButton
                            isLiked={reply.likes?.includes(currentUser?.uid)}
                            onLike={() =>
                              handleLike(
                                songId,
                                comment,
                                currentUser?.uid,
                                reply
                              )
                            }
                            onUnlike={() =>
                              handleUnlike(
                                songId,
                                comment,
                                currentUser?.uid,
                                reply
                              )
                            }
                            likeCount={reply.likes?.length || 0}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {currentUser && (
                <>
                  {replyInputVisible[comment.id] ? (
                    <CommentForm
                      currentUser={currentUser}
                      addComment={(_, newReply) => {
                        onAddReply(songId, comment.id, newReply); // Ensure comment.id is correct
                        toggleReplyInput(comment.id); // Hide input after reply
                      }}
                      songId={songId}
                      placeholder="Leave a reply"
                    />
                  ) : (
                    <div className={styles.leaveReplyButtonContainer}>
                      <button
                        className={styles.leaveReplyButton}
                        onClick={() => toggleReplyInput(comment.id)}
                      >
                        Leave Reply
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
        {showConfirmDialog && (
          <ConfirmDeleteDialog
            show={showConfirmDialog}
            message={
              selectedReplyIndex !== null
                ? 'Are you sure you want to delete your reply?'
                : 'Are you sure you want to delete your comment?'
            }
            onClose={() => setShowConfirmDialog(false)}
            confirm={handleConfirmDelete}
          />
        )}
      </div>
    </div>
  );
};

export default CommentList;
