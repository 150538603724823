import React from "react";
import SongListHome from "../components/SongListHome";

const Login = (props) => {
  return (
    <>
      <SongListHome />
    </>
  );
};

export default Login;
