import React from 'react';
import PropTypes from 'prop-types'; // If you want to use prop type validation
import styles from './LikedSong.module.scss';
import { Link } from 'react-router-dom';

const LikedSong = ({ song, openSongPage }) => {
  const handleClick = (e) => {
    e.preventDefault();
    openSongPage(song.id);
  };

  return (
    <Link to={`/song/${song.id}`} onClick={handleClick}>
      <div key={song.id} className={styles.songContainer}>
        <div
          style={{ backgroundImage: `url(${song.thumbnailURL})` }}
          alt={song.songName}
          className={styles.thumbnail}
        />
        <div>
          <p className={styles.songName}>{song.songName}</p>
          <p className={styles.songArtist}>{song.songArtist}</p>
        </div>
      </div>
    </Link>
  );
};

// Optional prop type validation
LikedSong.propTypes = {
  song: PropTypes.shape({
    id: PropTypes.string.isRequired,
    thumbnailURL: PropTypes.string,
    songName: PropTypes.string.isRequired,
    songArtist: PropTypes.string.isRequired,
  }).isRequired,
  openSongPage: PropTypes.func.isRequired,
};

export default LikedSong;
