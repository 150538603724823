import React, { useRef, useCallback, forwardRef, useEffect } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import styles from './Player.module.scss';

const extractVideoId = (url) => {
  const regex =
    /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const matches = url.match(regex);
  return matches ? matches[1] : null;
};

const Player = forwardRef(({ Url, onEnded }, ref) => {
  const playerRef = useRef(null);
  const videoId = extractVideoId(Url);
  const hasEndedRef = useRef(false);

  const handleVideoEnd = useCallback(() => {
    if (!hasEndedRef.current && onEnded) {
      console.log('[Player] Video ended, triggering callback');
      hasEndedRef.current = true;
      onEnded();
    }
  }, [onEnded]);

  useEffect(() => {
    hasEndedRef.current = false;
    let checkInterval;

    const checkVideoProgress = () => {
      const player = playerRef.current?.plyr;
      if (player) {
        const currentTime = player.currentTime;
        const duration = player.duration;

        if (duration && currentTime >= duration - 0.5 && !hasEndedRef.current) {
          handleVideoEnd();
        }
      }
    };

    checkInterval = setInterval(checkVideoProgress, 1000);

    return () => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    };
  }, [handleVideoEnd, Url]);

  return (
    <div className={styles.player}>
      <Plyr
        ref={playerRef}
        source={{
          type: 'video',
          sources: [{ src: videoId, provider: 'youtube' }],
        }}
        options={{
          autoplay: true,
          muted: true,
          controls: [
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'settings',
            'fullscreen',
          ],
          youtube: {
            noCookie: true,
            rel: 0,
            showinfo: 0,
            iv_load_policy: 3,
            modestbranding: 1,
            playsinline: 1,
            controls: 0,
            origin: window.location.origin,
            enablejsapi: 1,
          },
        }}
        events={{
          ended: handleVideoEnd,
        }}
      />
    </div>
  );
});

export default React.memo(Player);
